<template>
  <div
    :class="'agroup-page ' + LANGCODE"
    :style="'--aog-currency:\'' + SYSLANG.currency + '\';'"
  >
  <div>
	  <van-image
	    width="100%"
	    height="100%"
	    :src="defaultFace"
	    fit="contain"
	  />
    
  </div>
  </div>
</template>
  
<script>
import { getCookie } from "../util";
import { showLoadingToast, showToast, closeToast } from "vant";

export default {	
  data() {
    return {
      agentInfo: [],
      defaultFace: require("@/assets/images/20240919112618.jpg"),
      districount: 0,
      retailcount: 0,
      auditcount: 0,
    };
  },
  beforeCreate() {
    if (!getCookie("agentlogintoken")) {
      this.$router.replace({
        path: "/login",
      });
      return;
    }
  },
  created() {
    if (!getCookie("agentlogintoken")) {
      return;
    }

    this.PAGELANG = this.LANG.pages.agentgroup;
    this.SYSLANG = this.LANG.system;
    this.LANGCODE = this.langcode;

    this.init();
  },
  methods: {
    init() {
      showLoadingToast({
        message: this.SYSLANG.loading,
        duration: 0,
        forbidClick: true,
      });

      this.axios.get(this.actions.memberInfo).then((response) => {
        closeToast();
       let { code, message, data } = response.data;
        if (code == "200") {
          this.agentInfo = data;						
        } else {
          showToast({
            message: code == "NOTLOGIN" ? this.SYSLANG[message] : this.GOODSLANG[message],
            forbidClick: true,
            onClose: () => {
              if (code == "NOTLOGIN") {
                this.$router.replace({
                  path: "/login",
                });
              }
            },
          });
        }
      });
      this.axios
        .get(this.actions.memeberTeam)
        .then((res) => {
		  if(res.data.code!=200){
			   showToast({
				    message:res.data.message
			   })
		  }else{
			  
			  //this.agentInfo = res.data;
			  this.lv1count=res.data.data.agentBeansB.length;
			  this.lv2count=res.data.data.agentBeansC.length;
			  this.lv3count=res.data.data.agentBeansD.length;
			  closeToast();
		  }
          //closeToast();
        })
        .catch((error) => {
          console.log("views.AgentGroup.init.error", error);
          closeToast();
          //this.init();
        });
    },
  },
};
</script>


<style scoped>
@media (min-width: 679px) {
  .agroup-page {
    display: none;
  }

  .pc-my-page .main {
    padding: 36px 30px;
  }

  .pc-my-page .main .credit-info .item {
    cursor: pointer;
  }
}
</style>